<template>
  <div>
    <h1>Create</h1>
    <div></div>
  </div>
</template>

<script>
export default {
  computed: {},
  methods: {},
  created() {},
};
</script>

<style>
</style>
